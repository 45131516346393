define(['app', '$window', 'siteObj', 'cookie', '$console'], (app, window, siteObj, cookie, $console) => {

  const powerReviews = () => {
    const component = {};
    let setupConfig = [];

    const _config = {
      reviewWrapperUrl: `/power-reviews.list?pr_return_url=${window.location.origin}${window.location.pathname}`,
      qaEnabled: false,
      schemaCategoryRemoval: false,
      product: 'product',
      list: 'list',
      home: 'dept',
      writeReview: 'writeReview',
      searchResults: 'search-results',
      subscribeChannels: {
        recentlyViewedInitialised: 'recentlyViewed/initialised',
        reviewStars: 'productReviewStars/update',
        powerReviewsReinitialise: 'powerReviews/reinitialise'
      },
      publishCount: 0,
      targetPublishCount: 1
    }

    const _selectors = {
      productDescription: '.productDescription_contentPropertyListItem_synopsis .athenaProductPageSynopsisContent',
      powerReviewsDisplay: '.productPage_powerReviewDisplay',
      powerReviewsQADisplay: '[data-component="powerReviewQADisplay"]',
      productVariations: '[data-product-variation]',
      productVariationOptions: '.athenaProductVariationsOption',
      productBlock: '.productBlock[data-product-id]',
      productRecentlyViewed: 'productRecentlyViewedProducts',
      aboveElementStory: '.stry-wrapper-footer',
      aboveElementPwr: '.p-w-r',
      schemaCategoryRemoval: '[data-schema-category-removal]',
    }

    const _init = (element) => {
      component.element = element;

      if(document.querySelector(component.selectors.powerReviewsQADisplay) || document.querySelector(component.selectors.powerReviewsQADisplay)){
        component.config.qaEnabled = true;
      }

      if (document.querySelector(component.selectors.productRecentlyViewed)) {
        component.config.targetPublishCount = 2;
      }

      if (document.querySelector(component.selectors.schemaCategoryRemoval)) {
        component.config.schemaCategoryRemoval = true;
      }

      try {
        if (
          cookie.get('actualOptanonConsent') && cookie.get('actualOptanonConsent').includes('C0002') ||
          window.cookieName === 'actualOptanonConsent' && window.cookieValue.includes('C0002')

        ) {
          component.config.sm_data = 'ENABLED';
          component.config.enable_front_end_iovation_validation = true;
        } else  {
          component.config.sm_data = 'DISABLED';
          component.config.enable_front_end_iovation_validation = false;
        }
      } catch (error) {
        $console.log('OneTrust not Found')
      }

      window.onload = () => {
        if(window.OneTrust !== undefined) {
          component.getCookieConsent();
        }
      }

      component.config.pageType = component.element.classList.contains(component.config.writeReview) ? component.config.writeReview : siteObj.type ? siteObj.type : siteObj.page;

      setupConfig = component.createConfig(siteObj.productID);

      component.subscribe();

      if (setupConfig) {
        window.pwr = window.pwr || function () {
          (pwr.q = pwr.q || []).push(arguments);
        };
        window.pwr('render', setupConfig);
      }
      return component;
    }

    const _createConfig = (sku) => {
      switch (component.config.pageType) {
        case component.config.product:
          return component.createProductPageConfig(sku)
        case component.config.list:
        case component.config.home:
        case component.config.searchResults:
          return component.createListPageConfig()
        case component.config.writeReview:
          return component.createWriteReviewConfig()
        default:
          return null
      }
    }

    const _subscribe = () => {
      app.subscribe(component.config.subscribeChannels.recentlyViewedInitialised, function(sku) {
        component.config.publishCount++;

        if(component.config.publishCount === component.config.targetPublishCount){
          component.unmountComponents();
          component.remountComponents(sku);
          component.config.publishCount = 0;
        }
      });

      app.subscribe(component.config.subscribeChannels.reviewStars, function(sku) {
        component.config.publishCount++;

        if(component.config.publishCount === component.config.targetPublishCount){
          component.unmountComponents();
          component.remountComponents(sku);
          component.config.publishCount = 0;
        }
      });

      if(component.config.pageType === component.config.list || component.config.pageType === component.config.home ||component.config.pageType === component.config.searchResults){
        app.subscribe(component.config.subscribeChannels.powerReviewsReinitialise, function() {
          component.unmountComponents();
          component.remountComponents(siteObj.productID);
        })
      }
    }

    const _createProductPageConfig = (sku) => {
      const powerReviewsElement = document.querySelector(component.selectors.powerReviewsDisplay);

      if(!powerReviewsElement) {
        return false;
      }

      const components = {
        ReviewSnapshot: 'pr-review-snapshot',
        ReviewImageDisplay: 'pr-review-image-display',
        ReviewList: 'pr-review-list',
        ReviewSnippet: `pr-review-snippet-${sku}`,
      }

      if(component.config.qaEnabled){
        components['QuestionSnippet'] = `pr-qa-snippet-${sku}`;
        components['QuestionDisplay'] = `pr-qa-display-${sku}`;
      }

      const feedlessProductData = component.createFeedlessProductData(sku);
      const externalSkuClass = document.querySelector('.externalSku');
      const groupingId = powerReviewsElement.getAttribute('data-option-groupingId');
      const externalSku = externalSkuClass ? externalSkuClass.getAttribute('data-external-sku') : '';
      let pageId = sku;

      if (externalSku !== '') {
        pageId = groupingId === '' ? externalSku : groupingId;
      }

      let wrapperUrl = component.config.reviewWrapperUrl;
      if(groupingId !== '') {
        wrapperUrl += `&pr_page_id_variant=${externalSku}&pr_page_id=${groupingId}`
      }

      const productConfig = [{
        enable_client_side_structured_data: false,
        enable_content_collection_modal: false,
        api_key: siteObj.powerReviews.apiKey,
        merchant_group_id: siteObj.powerReviews.merchantGroupId,
        merchant_id: siteObj.powerReviews.merchantId,
        locale: siteObj.customerLocale,
        page_id: pageId,
        enable_front_end_iovation_validation: component.config.enable_front_end_iovation_validation,
        sm_data: component.config.sm_data,
        components: components,
        on_change: component.onChange,
        on_back_to_top_click: function() {

          let aboveElement = null;

          if (document.querySelector(component.selectors.aboveElementStory)){
            aboveElement = document.querySelector(component.selectors.aboveElementStory);
          } else {
            aboveElement = document.querySelector(component.selectors.aboveElementPwr);
          }

          if(aboveElement) {
            aboveElement.scrollIntoView({behavior: 'smooth'});
          }
        },
        review_wrapper_url: wrapperUrl,
        product: feedlessProductData.product,
      }, ...component.createListPageConfig()]

      if (feedlessProductData.variants && feedlessProductData.variants.length > 0 && groupingId !== '') {
        productConfig[0].product.variants = feedlessProductData.variants
      }

      return productConfig;
    }

    const _createWriteReviewConfig = () => {
      return {
        api_key: siteObj.powerReviews.apiKey,
        merchant_group_id: siteObj.powerReviews.merchantGroupId,
        merchant_id: siteObj.powerReviews.merchantId,
        locale: siteObj.customerLocale,
        sm_data: component.config.sm_data,
        enable_front_end_iovation_validation: component.config.enable_front_end_iovation_validation,
        components: {
          Write: 'pr-write-review'
        },
        on_submit: function(config, data) {
          window.scrollTo(0,0);
        },
      }
    }

    const _createListPageConfig = () => {
      const productList = document.querySelectorAll(component.selectors.productBlock);

      return [...productList].map((product) => {
        let productId = product.getAttribute('data-product-id');
        let snippetId = product.getAttribute('data-pr-snippet-id');
        let externalSku = product.getAttribute('data-external-sku');
        let groupingId = product.getAttribute('data-groupingId');
        let pageId = groupingId === '' ? externalSku : groupingId;

        if(groupingId === '' && externalSku === '') {
          pageId = productId;
        }

        let wrapperUrl = component.config.reviewWrapperUrl;
        if(groupingId !== '') {
          wrapperUrl += `&pr_page_id_variant=${externalSku}&pr_page_id=${groupingId}`
        }
        if(component.config.schemaCategoryRemoval) {
          return {
            api_key: siteObj.powerReviews.apiKey,
            merchant_group_id: siteObj.powerReviews.merchantGroupId,
            merchant_id: siteObj.powerReviews.merchantId,
            locale: siteObj.customerLocale,
            REVIEW_DISPLAY_SNAPSHOT_TYPE: "SIMPLE",
            ENABLE_CLIENT_SIDE_STRUCTURED_DATA: false,
            review_wrapper_url: wrapperUrl,
            page_id: pageId,
            sm_data: component.config.sm_data,
            enable_front_end_iovation_validation:
            component.config.enable_front_end_iovation_validation,
            components: {
              CategorySnippet: `pr-category-snippet-${snippetId}`,
            },
          };
        }
        return {
          api_key: siteObj.powerReviews.apiKey,
          merchant_group_id: siteObj.powerReviews.merchantGroupId,
          merchant_id: siteObj.powerReviews.merchantId,
          locale: siteObj.customerLocale,
          REVIEW_DISPLAY_SNAPSHOT_TYPE: 'SIMPLE',
          review_wrapper_url: wrapperUrl,
          page_id: pageId,
          sm_data: component.config.sm_data,
          enable_front_end_iovation_validation: component.config.enable_front_end_iovation_validation,
          components: {
            CategorySnippet: `pr-category-snippet-${snippetId}`
          }
        }
      })
    }

    const _createFeedlessProductData = (sku) => {
      const selectedVariant = document.querySelector(`.athenaProductVariationsOption[data-linked-product-id="${sku}"]`);
      const powerReviewsElement = document.querySelector(component.selectors.powerReviewsDisplay);
      const productUrl = selectedVariant && selectedVariant.getAttribute('data-option-url') ? siteObj.siteURL + selectedVariant.getAttribute('data-option-url') : document.querySelector('link[rel="canonical"]').href;
      const inStock = selectedVariant && selectedVariant.getAttribute('data-option-oos') ? selectedVariant.getAttribute('data-option-oos') : powerReviewsElement.getAttribute('data-no-stock') !== 'true';
      const description = document.querySelector(component.selectors.productDescription) ?
        component.stripHtmlTags(document.querySelector(component.selectors.productDescription).innerHTML) : '';

      let variants = [];
      if (document.querySelector(component.selectors.productVariations)) {
        let variationsList = document.querySelectorAll(component.selectors.productVariationOptions);
        variationsList.forEach(variation => {
          let externalSku = variation.getAttribute('data-option-optionExternalSku');
          let idVariant = externalSku === '' ? variation.getAttribute('data-option-id') : externalSku;
          variants.push( {
            name: variation.getAttribute('data-option-value'),
            image_url: variation.getAttribute('data-option-image'),
            upc: variation.getAttribute('data-option-barcode'),
            page_id_variant: idVariant,
          })
        })
      }

      return {
        product: {
          name: selectedVariant && selectedVariant.getAttribute('data-option-value') ? selectedVariant.getAttribute('data-option-value') : siteObj.productTitle,
          url: productUrl,
          image_url: selectedVariant && selectedVariant.getAttribute('data-option-image') ? selectedVariant.getAttribute('data-option-image') : powerReviewsElement.getAttribute('data-image-url').replace('.com//', '.com/'),
          description: description,
          brand_name: siteObj.productBrand,
          upc: selectedVariant && selectedVariant.getAttribute('data-option-barcode') ? selectedVariant.getAttribute('data-option-barcode') : powerReviewsElement.getAttribute('data-option-barcode'),
          price: selectedVariant && selectedVariant.getAttribute('data-option-price') ? selectedVariant.getAttribute('data-option-price') : (siteObj.productPrice.split(';')[1] || siteObj.productPrice),
          category_name: powerReviewsElement.getAttribute('data-option-category').replaceAll('/', '>'),
          in_stock: inStock,
        },
        variants: variants,
      }
    }

    const _unmountComponents = () => {
      setupConfig.forEach(config => {
        for(const [_, componentId] of Object.entries(config.components)){
          window.pwr('unmount', componentId);
        }
      })
    }

    const _remountComponents = (updatedSku) => {
      setTimeout(() => {
        setupConfig = component.createConfig(updatedSku);
        window.pwr('render', setupConfig);
      }, 1000)
    }

    const _onChange = (config, data) => {
      if(config.component === 'ReviewList') {

        if(data.review_count > 0 && data.reviews.length > 0) {
          const reviews = [];
          const aggregateRating = {
            '@type': 'AggregateRating',
            'ratingValue': data.average_rating,
            'reviewCount': data.review_count
          }

          for (let i = 0; i <= (data.reviews.length)-1; i++) {
            const pwrReview = data.reviews[i];
            const review = {
              '@type': 'Review',
              'name': pwrReview.details.headline,
              'description': pwrReview.details.comments,
              'datePublished': new Date(pwrReview.details.created_date).toISOString().split('T')[0],
              'author': {
                '@type': 'Person',
                'name': pwrReview.details.nickname
              },
              'reviewRating': {
                '@type': 'Rating',
                'ratingValue': pwrReview.metrics.rating
              }
            };
            reviews.push(review);
          }

          const scriptTag = document.getElementById('productSchema');
          const scriptTagObject = JSON.parse(scriptTag.innerHTML);
          scriptTagObject['aggregateRating'] = aggregateRating;
          scriptTagObject['review'] = reviews;
          scriptTag.innerHTML = JSON.stringify(scriptTagObject);
        }

        if(data.reviews.length === 0) {
          const scriptTag = document.getElementById('productSchema');
          const scriptTagObject = JSON.parse(scriptTag.innerHTML);
          if(scriptTagObject['review'] && scriptTagObject['review'].length >= 1) {
            delete scriptTagObject['review'];
          }
          scriptTag.innerHTML = JSON.stringify(scriptTagObject);
        }
      }
    }

    const _getCookieConsent = () => {
      OneTrust.OnConsentChanged(() => {
        if(OnetrustActiveGroups.includes('C0002')) {
          component.config.sm_data = 'ENABLED';
          component.config.enable_front_end_iovation_validation = true;
        } else {
          component.config.sm_data = 'DISABLED';
          component.config.enable_front_end_iovation_validation = false;
        }
        component.unmountComponents();
        component.remountComponents(siteObj.productID);
      })
    }

    const _stripHtmlTags = (str) => {
      const html = new DOMParser().parseFromString(str, 'text/html');
      const sanitisedStr = html.body.textContent.replace(/<[^>]+>/g, '');

      return sanitisedStr.replace(/\s\s+/g, ' ') || '';
    }

    component.init = _init;
    component.config = _config;
    component.selectors = _selectors;
    component.subscribe = _subscribe;
    component.createConfig = _createConfig;
    component.createListPageConfig = _createListPageConfig;
    component.createProductPageConfig = _createProductPageConfig;
    component.createWriteReviewConfig = _createWriteReviewConfig
    component.unmountComponents = _unmountComponents;
    component.remountComponents = _remountComponents;
    component.getCookieConsent = _getCookieConsent;
    component.createFeedlessProductData = _createFeedlessProductData;
    component.onChange = _onChange;
    component.stripHtmlTags = _stripHtmlTags;

    return component;
  }
  return powerReviews;
});
